import React from "react";
import { Container, Title } from "@mantine/core";
import TeamMember from "./TeamMember";
import { RichText } from "prismic-reactjs";

const Team = ({ title, members }) => {
  return (
    <Container id="team" className="gap-2 mt-12 md:mt-20" size={1440}>
      <Title className="text-center uppercase mb-10 text-3xl md:text-5xl">{title}</Title>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {members.map((member) => {
          return (<TeamMember
            src={member.image.url}
            title={member.title}
            socials={[
              { type: "instagram", link: member.instagram.url },
              { type: "twitter", link: member.twitter.url },
              {type: "youtube", link: member.youtube.url},
              {type: "tiktok", link: member.tiktok.url},
            ]}
            description={RichText.render(member.description)} />)
        })}
      </div>
    </Container>
  );
};

export default Team;
