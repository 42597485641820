import {
  ActionIcon,
  Burger,
  Header,
  MediaQuery,
  Center,
  Anchor,
  Drawer,
} from "@mantine/core";
import React, { useState } from "react";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useSinglePrismicDocument } from "@prismicio/react";

const socialIcons = {
  Discord: <FontAwesomeIcon icon={brands("discord")} className="fa-lg" />,
  Instagram: <FontAwesomeIcon icon={brands("instagram")} className="fa-lg" />,
  Facebook: <FontAwesomeIcon icon={brands("facebook")} className="fa-lg" />,
  Twitter: <FontAwesomeIcon icon={brands("twitter")} className="fa-lg" />,
};

function AppHeader() {
  const [opened, setOpened] = useState(false);
  const [document] = useSinglePrismicDocument("header");
  const links = document?.data?.links || [];
  const social = document?.data?.social_links || [];
  const logo = document?.data?.logo?.url || "/logo.png";

  const navlinks = (
    <>
      <nav className="flex md:flex-row flex-col md:gap-4 justify-center items-center border-0">
        {links.map((link) => (
          <Anchor
            key={link.label}
            className="text-lg uppercase px-6 tracking-wider min-w-fit"
            href={link.url.url}
            target={link.url.target}
          >
            {link.label}
          </Anchor>
        ))}
      </nav>
    </>
  );

  const socialLinks = social.map((link) => (
    <ActionIcon
      key={link.type}
      variant="transparent"
      className="text-xl md:text-2xl text-black appearance-none"
      size={50}
      component="a"
      target="_blank"
      // TODO: change link
      href={link.url.url}
    >
      {socialIcons[link.type]}
    </ActionIcon>
  ));

  return (
    <>
      <Center id="overlay" className="bg-black z-50 h-[150px]">
        <img className="animate-pulse h-[200px]" src={logo} alt="her logo" />
      </Center>
      <Header
        height={106}
        p="lg"
        className="flex md:justify-between px-13 border-0"
      >
        <div className="flex justify-between w-full">
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <div>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                mr="xl"
              />
              <Drawer
                opened={opened}
                onClose={() => setOpened(false)}
                padding="xl"
                size="xl"
              >
                {navlinks}
              </Drawer>
            </div>
          </MediaQuery>
          <div className="flex">{socialLinks}</div>
        </div>
        <div className="hidden md:block w-full">{navlinks}</div>
      </Header>
    </>
  );
}

export default AppHeader;
