import * as prismic from '@prismicio/client'

// Fill in your repository name
export const repositoryName = 'https://her-nft.prismic.io/api/v2'

export const client = prismic.createClient(repositoryName, {
  // If your repo is private, add an access token
  accessToken: 'MC5Zbk01UWhJQUFDTUFTbUxS.77-977-977-9Uu-_ve-_vTfvv73vv70MAO-_ve-_ve-_ve-_ve-_vWF2DgXvv73vv73vv73vv73vv70hVVfvv73vv73vv73vv70',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [
    {
      type: 'home_page',
      path: '/',
    },
  ],
})