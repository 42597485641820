import { AppShell, Button, Container, MantineProvider, Title, Image, Space } from "@mantine/core";
import React from "react";
import { Routes, Route } from "react-router-dom";
import AppHeader from "../components/header/Header";
import Gallery from "../components/gallery/Gallery";
import About from "../components/about/About";
import mantineTheme from "../mantine.config";
import Team from "../components/team/Team";
import FAQ from "../components/faq/FAQ";
import AppFooter from "../components/footer/Footer";
import { useSinglePrismicDocument } from "@prismicio/react";
import Home from './Home';
import Lore from './Lore';

function App() {
  const [document] = useSinglePrismicDocument('home_page');
  if (!document) return <></>;
  return (
    <MantineProvider
      theme={mantineTheme}
    >
      <AppShell
        padding="0"
        header={<AppHeader />}
        footer={<AppFooter />}
        // className="w-full px-0"
      >
        <Routes>
          <Route index element={<Home />} />
          <Route path="/lore" element={<Lore />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </AppShell>
    </MantineProvider>
  );
}

export default App;
