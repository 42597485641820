import React from "react";
import { createStyles, Text } from '@mantine/core';


const useStyles = (opened) => createStyles((theme, _params, getRef) => ({
  wrapper: {
    background: opened ? "#fff" : "#000",
    border: "2px solid #000000",
    borderRadius: "15px",
    height: "fit-content",
  },
  title: {
    padding: "14px",
    margin: 0,
    color: opened ? "#000" : "#ffffff",
    textAlign: "center",
  },
  text: {
    display: opened ? "block" : "none",
    padding: "0 14px 14px",
    margin: 0,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.03rem",
    color: opened ? "#000" : "#ffffff",
  }
}));

const Accordion = ({ id, title, text, opened, toggleAccordion }) => {
  const { classes } = useStyles(opened)();

  return (
    <div className={classes.wrapper} onClick={() => toggleAccordion(id)}>
      <Text size="lg" className={classes.title}>{title}</Text>
      <Text size="lg" className={classes.text}>{text}</Text>
    </div>
  )
};

export default Accordion;
