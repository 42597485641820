import React from "react";
import { Container, Title, Text, Image } from "@mantine/core";
import { useSinglePrismicDocument } from "@prismicio/react";
import { RichText } from "prismic-reactjs";

const NFTProject = () => {
  const [document] = useSinglePrismicDocument("lore");
  console.log(document);
  const data = document?.data || {};
  const { section_1_bottom, section_1_left, section_1_text, title } = data;
  const { section_2, section_2_background, section_2_text } = data;
  const { section_3, section_3_text } = data;
  const { section_4, section_4_text, section_4_background } = data;
  const {
    section_5_left,
    section_5_text_bottom,
    section_5_text_right,
    section_5_right,
  } = data;
  return (
    <>
      <Container id="about" size={1440}>
        <div className="flex flex-col justify-between items-center md:flex-row md:gap-10">
          {section_1_left?.url && (
            <Image
              radius="sm"
              src={section_1_left?.url}
              alt="Random women in forest"
              style={{ maxWidth: 391 }}
            />
          )}
          <div>
            {title && title.length > 0 && (
              <Title className="uppercase mt-10 mb-5 text-3xl md:text-5xl md:mt-40">
                <RichText render={title} />
              </Title>
            )}
            {section_1_text && section_1_text?.length > 0 && (
              <Text className="text-lg">
                <RichText render={section_1_text} />
              </Text>
            )}

            {section_1_bottom?.url && (
              <Image
                radius="sm"
                src={section_1_bottom?.url}
                alt="Random women in forest"
                className="mt-12 ml-auto"
                style={{ maxWidth: 673 }}
              />
            )}
          </div>
        </div>
      </Container>
      <div className="bg-[url('/public/lore-bg.jpg')] px-0 mx-0 w-full">
        <Container id="about" size={1440} className="mt-12 py-12">
          {section_2_text && section_2_text.length > 0 && (
            <Text className="m-auto text-lg text-white md:max-w-[50%]">
              <RichText render={section_2_text} />
            </Text>
          )}

          {section_2?.url && (
            <Image
              radius="sm"
              src={section_2?.url}
              alt="Random women in forest"
              className="mt-12"
              style={{ maxWidth: 673 }}
            />
          )}
        </Container>
      </div>
      <Container id="about" size={1440} className="py-12">
        {section_3_text && section_3_text.length > 0 && (
          <Text className="m-auto text-lg md:max-w-[50%]">
            <RichText render={section_3_text} />
          </Text>
        )}
        {section_3?.url && (
          <Image
            radius="sm"
            src={section_3?.url}
            alt="Random women in forest"
            className="mt-12 ml-auto"
            style={{ maxWidth: 673 }}
          />
        )}
      </Container>
      <div className="bg-[url('/public/lore-bg.jpg')] px-0 mx-0 w-full">
        <Container id="about" size={1440} className="pt-20 pb-12 md:mt-12">
          {section_4_text && section_4_text.length > 0 && (
            <Text className="text-lg text-white md:max-w-[50%]">
              <RichText render={section_4_text} />
            </Text>
          )}

          {section_4?.url && (
            <Image
              radius="sm"
              src={section_4?.url}
              alt="Random women in forest"
              className="mt-12 m-auto"
              style={{ maxWidth: 673 }}
            />
          )}
        </Container>
      </div>
      <Container id="about" size={1440} className="py-12">
        <div className="relative">
          <div className="ml-auto md:absolute right-24 md:max-w-[30%]">
            {section_5_text_right && section_5_text_right.length > 0 && (
              <Text className="text-lg">{section_5_text_right}</Text>
            )}
            {section_5_right?.url && (
              <Image
                radius="sm"
                src={section_5_right?.url}
                alt="Random women in forest"
                className=""
                style={{ maxWidth: 416 }}
              />
            )}
          </div>
          <div className="flex justify-center items-center gap-8 md:my-20">
            {section_5_left?.url && (
              <Image
                radius="sm"
                src={section_5_left?.url}
                alt="Random women in forest"
                className="mt-12"
                style={{ maxWidth: 416 }}
              />
            )}
            <div className="mt-28">
              {section_5_text_bottom && section_5_text_bottom.length > 0 && (
                <Text className="text-lg md:max-w-[60%] mb-4">
                  <RichText render={section_5_text_bottom} />
                </Text>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default NFTProject;
