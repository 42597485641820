import React from "react";
import { Image } from "@mantine/core";

const items = [
  { src: "gallery/gallery-image-1.png", alt: "Egyptian Goddess" },
  { src: "gallery/gallery-image-2.png", alt: "Egyptian Goddess" },
  { src: "gallery/gallery-image-3.png", alt: "Geisha" },
  { src: "gallery/gallery-image-4.png", alt: "Girl" },
  { src: "gallery/gallery-image-5.png", alt: "Girl" },
  { src: "gallery/gallery-image-6.png", alt: "Crypto Girl" },
  { src: "gallery/gallery-image-7.png", alt: "Leo" },
  { src: "gallery/gallery-image-8.png", alt: "Greek Sculpture" },
  { src: "gallery/gallery-image-9.png", alt: "Egyptian Goddess" },
  { src: "gallery/gallery-image-10.png", alt: "Egyptian Goddess" },
];

const list2 = [
  { src: "gallery/gallery-image-11.png", alt: "Egyptian Goddess" },
  { src: "gallery/gallery-image-12.png", alt: "Egyptian Goddess" },
  { src: "gallery/gallery-image-13.png", alt: "Egyptian Goddess" },
  { src: "gallery/gallery-image-14.png", alt: "Egyptian Goddess" },
  { src: "gallery/gallery-image-15.png", alt: "Geisha" },
  { src: "gallery/gallery-image-16.png", alt: "Egyptian Goddess" },
  { src: "gallery/gallery-image-17.png", alt: "Indian Goddess" },
  { src: "gallery/gallery-image-18.png", alt: "Mermaid Goddess" },
  { src: "gallery/gallery-image-19.png", alt: "Pop Art" },
  { src: "gallery/gallery-image-20.png", alt: "Pop Art" },
  { src: "gallery/gallery-image-21.png", alt: "Princess Lea" },
];

const GalleryList = ({ opposite, images }) => {
  const mappedList = images?.map(({ image }) => {
    return {
      src: image.url,
      alt: image.alt,
    };
  });
  let list = opposite ? mappedList : mappedList.reverse();

  return (
    <>
      {list.map((item) => {
        return (
          <Image key={item.src} radius="md" src={item.src} alt={item.alt} />
        );
      })}
    </>
  );
};

export default GalleryList;
