import {
  AppShell,
  Button,
  Container,
  MantineProvider,
  Title,
  Image,
  Space,
} from "@mantine/core";
import React from "react";
import { Routes, Route } from "react-router-dom";
import AppHeader from "../components/header/Header";
import Gallery from "../components/gallery/Gallery";
import About from "../components/about/About";
import mantineTheme from "../mantine.config";
import Team from "../components/team/Team";
import FAQ from "../components/faq/FAQ";
import AppFooter from "../components/footer/Footer";
import { useSinglePrismicDocument } from "@prismicio/react";

function Home() {
  const [document] = useSinglePrismicDocument("home_page");
  console.log(document);
  if (!document) return <></>;
  return (
    <Container className="m0" style={{ maxWidth: 1400 }}>
      <Image
        radius="md"
        src={document.data.main_image.url}
        alt="Random unsplash image"
      />
      <Gallery
        images={document.data.gallery}
        title={document.data.gallery_title}
      />
      <About
        title={document.data.about}
        description={document.data.about_description}
      />
      <Team title={document.data.team_title} members={document.data.team} />
      <div id="roadmap" className="mt-12 md:mt-20">
        <Image src={document.data.roadmap_image.url} radius="xl" />
      </div>
      <FAQ title={document.data.faq_title} items={document.data.faq} />
    </Container>
  );
}

export default Home;
