import { Container } from "@mantine/core";
import React from "react";
import NFTProject from "../components/nft-project/NFTProject";

import { useSinglePrismicDocument } from "@prismicio/react";

function Lore() {
  const [document] = useSinglePrismicDocument('home_page');
  if (!document) return <></>;
  return (
    <Container className="m0 w-full p-0" style={{ maxWidth: "none" }}>
      <NFTProject />
    </Container>
  );
}

export default Lore;
