import React from "react";
import { Image, Text, ActionIcon } from "@mantine/core";
import { useHover } from '@mantine/hooks';
import {
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const TeamMember = ({ src, title, description, socials = [] }) => {
  const { hovered, ref } = useHover();
  return (
    <div className="relative" style={{ maxWidth: 410 }}>
      <p className="text-center mb-3 md:text-lg md:min-h-56 md:flex md:justify-center md:items-center">{title}</p>
      <div ref={ref} className="relative">
        <Image
          radius="md"
          src={src}
          alt={title}
        />
        <div className={`${hovered ? "opacity-1" : "opacity-0"} absolute w-full h-full z-10 top-0 cursor-pointer fade-in`}>
          <div className="absolute w-full h-full z-20 rounded-2xl" style={{ backgroundColor: '#000', opacity: .3 }} />
          <div className="absolute p-4 z-30 h-full overflow-y-scroll">
            <Text color="white">{description}</Text>
            <div className="flex">
              {socials.map(({ type, link }) => {
                const brandIcon = type === "instagram" ? brands("instagram") : type === "twitter" ? brands("twitter") : type === "youtube" ? brands("youtube") : brands("tiktok");
                if(!link) return <></>;
                return (
                  <ActionIcon
                    variant="transparent"
                    className="text-3xl text-black appearance-none"
                    size={50}
                    component="a"
                    target="_blank"
                    href={link}
                  >
                    <FontAwesomeIcon icon={brandIcon} className="fa-lg" color="white" />
                  </ActionIcon>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
