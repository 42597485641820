import { Text, Center } from "@mantine/core";
import { useSinglePrismicDocument } from "@prismicio/react";
import React from "react";

function Footer() {
  const [document] = useSinglePrismicDocument("footer");
  const copyright = document?.data?.copyright || "";

  return (
    <Center style={{ height: 125, backgroundColor: "#000", color: "#fff" }}>
      <Text>{copyright}</Text>
    </Center>
  );
}

export default Footer;
