import React, { useState } from "react";
import { Container, Title } from "@mantine/core";

import Accordion from "../accordion/Accordion";
import { RichText } from "prismic-reactjs";

const FAQ = ({ title, items }) => {
  const [openedId, setOpenedId] = useState(0);
  const handleAccordionClick = (id) => {
    setOpenedId(id);
  };
  return (
    <Container id="faq" className="gap-2 mt-12 md:mt-20 mb-10" size={1440}>
      <Title className="text-center uppercase mb-10 text-3xl md:text-5xl">
        {title}
      </Title>
      <div className="flex flex-col md:flex-row md:grid grid-cols-2 justify-between flex-wrap gap-6">
        {items.map((faq, i) => {
          return (
            <Accordion
              id={i}
              key={i}
              opened={openedId === i}
              toggleAccordion={handleAccordionClick}
              title={faq.question}
              text={RichText.render(faq.answer)}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default FAQ;
