import React from "react";
import { Container, Title } from "@mantine/core";
import video from "./bg-video.mp4";
import { RichText } from "prismic-reactjs";

const About = ({ title, description }) => {
  return (
    <Container id="about" className="gap-2 mt-12 md:mt-20 bg-black text-white p-8 rounded-3xl" size={1440}>
      <Title className="text-center uppercase mb-10 text-3xl md:text-5xl">{title}</Title>
      <div className="laptop:flex justify-between">
        <div className="mb-3 laptop:w-2/4 laptop:mr-7 laptop:mb-3">
         {RichText.render(description)}
        </div>
        
        <video width="650" playsInline autoPlay muted loop >
          <source src={video} type="video/mp4"/>
        </video>
      </div>
    </Container>
  );
};

export default About;
