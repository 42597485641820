import React from "react";
import { Container, Space, Title } from "@mantine/core";

import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import useWindowSize from "../../hooks/useWindowSize";
import GalleryList from "./GalleryList";

const Gallery = ({ images, title }) => {
  const size = useWindowSize();
  const isMobile = size.width < 768;

  const options = {
    items: isMobile ? 2 : 5,
    rewind: true,
    autoplay: true,
    loop: true,
    autoplayTimeout: 1250,
    margin: 10,
  };

  const options2 = {
    ...options,
    rtl: true,
  };

  return (
    <div className="gap-2 mt-12 md:mt-24" size={1440}>
      <Title className="text-center uppercase mb-10 text-3xl md:text-5xl">
        {title}
      </Title>
      <OwlCarousel options={options}>
        <GalleryList images={images} />
      </OwlCarousel>
      <Space h="sm" />
      <OwlCarousel options={options2}>
        <GalleryList opposite={true} images={images} />
      </OwlCarousel>
    </div>
  );
};

export default Gallery;
